<template>

  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">Details {{ formData.id }}</div>
      </template>

      <div v-if="formState == 'Update'">
        <DetailDaysView :key="formData.id" :data="formData"
                        :programmationsuser="formData.programmationsuser"></DetailDaysView>

      </div>


      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
    <div class="col-sm-12">

      <AgGridTable :key="userSelect" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
                   :extras-data="extrasData" :getRowStyle="getRowStyle" :maxBlocksInCache="maxBlocksInCache"
                   :pagination="pagination" :paginationPageSize="paginationPageSize"
                   :rowData="rowData"
                   :rowModelType="rowModelType" :showExport="false" :sideBar="false" :url="url"
                   className="ag-theme-alpine"
                   domLayout='autoHeight' rowSelection="multiple" @gridReady="onGridReady">
        <template #header_buttons>

        </template>

      </AgGridTable>
    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';


export default {
  name: 'historiquebadge',
  components: {
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    DaysTraitements: () => import("../Taches/Horaireagents/DaysTraitements.vue"),
    TypeAgentsTraitements: () => import("../Taches/Horaireagents/TypeAgentsTraitements.vue"),
    DetailDaysView: () => import("../Programmations/DetailDaysView.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
  },
  props: {
    Type: String,
    userSelect: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      search: "",
      formId: "perms",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/historiques-Aggrid1',
      table: 'perms',
      usersData: [],
      requette: 1,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 10,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
    }
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
    extrasData: function () {
      let params = {}
      params['programmes.user_id'] = {values: [this.userSelect], filterType: 'set'}
      // params['type'] = {values: ['ficheAgents'], filterType: 'set'}
      return {baseFilter: params}


    },
    hideColumn() {
      return this.Type === 'BadgeUser'; // Remplacez 'valeur' par la valeur correspondante pour masquer la colonne
    },

  },
  watch: {
    '$route': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
        this.tableKey++
      },
      deep: true
    },
    'extrasData': {
      handler: function (after, before) {

        this.gridApi.sizeColumnsToFit();
        this.gridApi.refreshServerSide()
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/programmes-Aggrid1'

    this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {

    this.columnDefs =
        [
          {
            field: "programmes.id",
            sortable: true,
            hide: true,
          },
          {
            field: "programmations.date_debut",
            minWidth: 120, maxWidth: 120,
            sortable: true,
            headerName: 'date ',
            filterParams: {suppressAndOrCondition: true},
            join: {
              table: 'programmations',
              champ2: 'programmations.id',
              champ1: 'programmes.programmation_id',
              operateur: '=',
            }
          },
          {
            headerName: 'zone',
            field: 'zones.libelle',
            minWidth: 120, maxWidth: 120,
            filterParams: {suppressAndOrCondition: true},
            join: {
              table: 'zones',
              champ2: 'zones.id',
              champ1: 'programmations.zone_id',
              operateur: '=',
            }
          },
          {
            headerName: 'horaire',
            field: 'horaires.libelle',
            minWidth: 120, maxWidth: 120,
            filterParams: {suppressAndOrCondition: true},
            join: {
              table: 'horaires',
              champ2: 'horaires.id',
              champ1: 'programmes.horaire_id',
              operateur: '=',
            }
          },
          {
            headerName: 'poste',
            field: 'postes.libelle',
            minWidth: 120, maxWidth: 120,
            filterParams: {suppressAndOrCondition: true},
            join: {
              table: 'postes',
              champ2: 'postes.id',
              champ1: 'horaires.poste_id',
              operateur: '=',
            }
          },
          {
            headerName: 'site',
            field: 'sites.libelle',
            minWidth: 120, maxWidth: 120,
            filterParams: {suppressAndOrCondition: true},
            join: {
              table: 'sites',
              champ2: 'sites.id',
              champ1: 'postes.site_id',
              operateur: '=',
            }
          },
          {
            headerName: 'client',
            field: 'clients.libelle',
            minWidth: 120, maxWidth: 120,
            filterParams: {suppressAndOrCondition: true},
            join: {
              table: 'clients',
              champ2: 'clients.id',
              champ1: 'sites.client_id',
              operateur: '=',
            }
          },
          {
            headerName: 'etats',
            field: null,
          },

        ];

  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }

    // this.getusers();

  },
  methods: {
    getRowStyle(params) {
      let style = {}
      console.log('okkkk', params.data)
      // console.log('okkkk', params.data.presence_declarer_auto +'-'+ params.data.presence_declarer_manuel )
      try {

        // if (params.data.presence_declarer_auto +'-'+ params.data.presence_declarer_manuel == 'oui-oui' || params.data.presence_declarer_auto +'-'+ params.data.presence_declarer_manuel == 'non-oui' || params.data.presence_declarer_auto +'-'+ params.data.presence_declarer_manuel == 'oui-' || params.data.presence_declarer_auto +'-'+ params.data.presence_declarer_manuel == '-oui' ) {
        if (params.data.jour_presences == 1) {
          // style.background = "#2885a7"
        } else {
          style.background = "#ff000070"
        }
      } catch (e) {

      }
      return style;
    },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
      this.gridApi.sizeColumnsToFit();
    },
    getusers() {
      this.axios.get('/api/users').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.usersData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

  }
}
</script>
